/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          linkedIn
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        siteDescription={data.site.siteMetadata?.description || `Description`}
      />
      <div
        style={{
          background: `linear-gradient(180deg, rgba(49,49,49,1) 0px, rgba(52,53,52,1) 20px, rgba(0,0,0,1) 300px)`,
        }}
      >
        <div className={`max-w-4xl mx-auto px-8 pt-4`}>
          <main>{children}</main>
          <footer
            className={`text-center sm:text-left py-8 text-xs sm:w-1/3 px-8 sm:float-right`}
          >
            {data.site.siteMetadata.linkedIn ? (
              <>
                <a
                  href={data.site.siteMetadata.linkedIn}
                  className="opacity-75 hover:opacity-100"
                >
                  LinkedIn
                </a>
                {` `}
              </>
            ) : (
              ``
            )}
            <span className="opacity-40">
              © {new Date().getFullYear()}{" "}
              {data.site.siteMetadata?.title || `Title`}
            </span>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
