import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle, siteDescription }) => (
  <header
    style={{
      background: `linear-gradient(0deg, rgba(59,58,57,1) 0%, rgba(77,77,77,1) 100%)`,
    }}
  >
    <div className={`flex max-w-4xl mx-auto px-8 items-center`}>
      <h1 className={`m-0 w-2/3 relative top-1 md:top-0`}>
        <Link
          to="/"
          className={`text-white no-underline mt-6 block md:flex items-baseline`}
        >
          <svg
            className={`w-48 sm:w-72`}
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 589 131"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>{siteTitle}</title>
            <desc>{siteTitle} Logo</desc>}
            <g transform="matrix(.919519 0 0 .821404 -24.4435 -22.6825)">
              <path d="m26.583 27.614h640.451v159.432h-640.451z" fill="none" />
              <g
                fill="#fff"
                fillRule="nonzero"
                transform="matrix(1.08753 0 0 1.21743 -82.853 14.0281)"
              >
                <path d="m103.794 68.763c0 13.596 5.412 24.948 13.86 31.548 6.996 5.676 15.972 8.052 24.684 8.052 7.92 0 16.236-2.244 26.664-6.6-.264-1.716-.792-3.564-1.32-5.148-6.732 3.036-15.84 6.072-24.288 6.072-9.636 0-18.876-4.092-23.892-10.032-6.6-7.788-8.316-15.18-8.316-26.532 0-12.804 4.884-23.628 12.54-31.284 6.468-6.6 15.048-9.372 23.232-9.372 4.884 0 8.976.396 12.276 2.64.396.264.924.924 1.056 2.112l1.98 14.388c1.848-.132 3.96-.396 5.676-.66-.396-6.996-.792-13.992-1.188-20.988-3.168-1.584-9.636-3.036-16.632-3.036-8.58 0-19.932 1.98-29.304 9.9s-17.028 20.724-17.028 38.94z" />
                <path d="m192.366 25.467c-4.092 0-6.204 2.772-6.204 5.94 0 2.904 2.244 5.676 5.808 5.676 3.432 0 5.544-2.508 5.544-5.808 0-2.244-1.056-5.808-5.148-5.808zm-3.036 33v43.296c0 .264-.264.528-.528.528l-9.24.396c-.132 1.452-.132 2.904-.132 4.356 4.224 0 8.448-.132 12.54-.132h13.728c.132-1.32.132-2.772.132-4.224l-9.768-.396c0-14.256.264-36.3.264-50.952 0-.264 0-.396-.264-.396-1.188 0-12.276 2.376-17.424 3.168v3.96z" />
                <path d="m222.462 58.599.132 43.296c0 .264-.264.396-.396.396l-8.316.396c0 1.452-.132 3.036-.132 4.356 3.564 0 7.656-.132 11.616-.132 4.884 0 10.164.132 14.256.132.132-1.452.132-3.036.132-4.356l-10.428-.396c0-10.164.132-26.268.264-36.828 5.808-4.62 13.992-8.58 19.404-8.58 3.432 0 7.26.396 10.164 5.676 1.848 3.168 1.848 8.712 1.848 13.86 0 7.92-.132 17.82-.132 25.476 0 .264-.132.396-.396.528l-8.448.396v4.356c3.432 0 8.052-.132 11.616-.132 4.356 0 8.976-.132 12.936-.132v-4.224l-8.844-.396c.132-10.296.264-23.1.264-33.264 0-2.904-.66-6.204-2.376-9.24-2.376-4.092-6.864-8.712-14.124-8.712-5.544 0-8.316.264-21.648 8.448l-.66-.132c-.132-1.716-.924-5.412-1.188-7.92 0-.132-.132-.264-.264-.264-1.056 0-10.956 2.376-15.708 3.3-.132 1.188-.264 2.64-.264 3.96z" />
                <path d="m328.854 102.423c1.32 3.168 2.508 4.884 3.696 5.94 4.356 0 11.352-1.32 13.464-1.98.132-1.452.132-2.904.132-4.092l-9.504.264c-1.32.132-1.716-1.056-1.716-3.168 0-26.004.264-60.192.396-84.216 0-.396-.132-.528-.396-.528-1.188 0-11.748 2.376-18.084 3.3v4.092l11.22.132c.132 10.692.132 20.196.132 31.416l-.396.396c-4.092-1.716-8.712-2.904-13.464-2.904-9.108 0-15.576 3.432-20.064 8.58-5.148 5.808-8.316 14.124-8.316 22.308 0 8.448 2.376 14.916 7.524 19.932 3.828 3.96 9.636 6.468 16.236 6.468 4.884 0 7.656-.528 18.48-5.94zm-.924-6.996c0 .792 0 1.32-.66 1.848-4.62 3.3-10.032 5.94-16.5 5.94-4.62 0-9.372-1.584-12.672-5.94-3.3-4.488-4.62-9.24-4.62-16.236 0-7.524 1.716-13.068 5.148-17.688 3.564-4.62 8.844-7.128 15.048-7.128 5.016 0 9.768 1.452 14.256 3.828.132 10.428 0 22.836 0 35.376z" />
                <path d="m406.866 56.619c.132-1.452.132-2.904.132-4.224h-10.032c-4.488 0-8.976.132-12.804.132-.132 1.188-.132 2.772-.132 4.224l8.976.264c.396 0 .528.264.396.66-1.452 4.356-3.696 11.22-7.656 21.78-3.3 8.316-5.412 13.2-8.184 19.8h-.792c-2.244-6.204-4.884-13.332-8.052-22.704-3.168-8.976-5.412-15.708-6.468-19.536l10.164-.264c.132-1.452.132-3.036.132-4.356h-25.344c-.132 1.188-.132 2.904-.132 4.224l6.864.396c.264 0 .66.132.66.396 2.112 5.28 4.224 11.352 7.524 20.064 3.828 10.164 6.996 18.216 11.352 29.04-2.244 5.412-6.468 12.144-9.9 16.5-3.3 4.224-7.524 8.184-12.54 11.616 0 1.188 4.356 4.356 5.94 4.356 3.696-1.98 7.524-6.6 11.748-13.068 6.996-10.428 17.028-32.076 21.384-42.636 5.148-12.54 9.24-22.572 10.692-26.268z" />
                <path d="m441.978 102.423c-.132 1.452-.132 3.168-.132 4.62h55.308c1.584-6.336 3.168-13.2 4.488-19.8-1.452-.396-3.3-.66-5.148-1.056l-4.356 12.54c-.396 1.32-1.056 2.244-2.376 2.508-2.244.66-10.956 1.056-23.1 1.056-7.392 0-7.524-.396-7.524-2.64l.132-34.056c8.844 0 16.368 0 23.1.132 2.376 0 3.3.66 3.432 2.508l.396 7.26c1.716.132 3.564 0 5.148 0v-12.012c.132-4.092.132-8.316.264-12.276h-5.016l-.66 6.732c-.132 1.716-.66 2.508-3.432 2.64-8.052.132-14.916.132-23.232.264l.132-34.584c.792 0 3.036-.132 4.62-.132 8.712 0 19.14.264 22.968.66 1.98.264 2.772.924 3.036 2.244l2.112 11.88c1.848-.132 3.696-.396 5.28-.66-.264-6.336-.66-12.804-1.056-19.008h-10.956c-3.3 0-24.948.132-28.776.132-5.148 0-10.164-.132-14.652-.132-.132 1.584-.132 3.168-.132 4.752l10.296.264v75.372c0 .264-.132.396-.396.396z" />
                <path d="m526.59 58.071c-.264-1.98-.66-4.488-1.056-6.6 0-.264 0-.396-.264-.396-.924 0-10.956 2.64-15.708 3.564-.132 1.188-.132 2.64-.132 3.828l10.56.132v72.6c0 .396-.264.66-.528.66l-7.92.528c-.132 1.452-.132 3.036-.132 4.356 3.564-.132 7.92-.132 11.88-.132 5.412-.132 11.352-.132 16.104-.132 0-1.452 0-3.036.132-4.356l-12.804-.264c0-7.392.132-19.008 0-26.532l.528-.396c3.96 2.112 8.052 3.432 13.992 3.432 6.072 0 11.748-1.716 14.784-3.696 5.808-3.828 8.184-7.656 10.56-12.804 2.244-5.016 2.904-9.504 2.904-15.972.132-10.824-7.524-24.816-21.648-24.816-5.544 0-7.788.528-20.724 7.128zm35.376 20.592c0 9.504-2.772 15.708-7.26 20.064-3.564 3.432-8.58 4.884-12.804 4.884-7.656 0-14.916-4.224-14.916-7.26 0-9.9.132-21.78.132-32.472 6.072-4.488 13.068-6.864 18.612-6.864 5.676 0 10.692 3.036 13.2 7.788s3.036 9.504 3.036 13.86z" />
                <path d="m594.174 58.071c-.264-1.98-.66-4.488-1.056-6.6 0-.264 0-.396-.264-.396-.924 0-10.956 2.64-15.708 3.564-.132 1.188-.132 2.64-.132 3.828l10.56.132v72.6c0 .396-.264.66-.528.66l-7.92.528c-.132 1.452-.132 3.036-.132 4.356 3.564-.132 7.92-.132 11.88-.132 5.412-.132 11.352-.132 16.104-.132 0-1.452 0-3.036.132-4.356l-12.804-.264c0-7.392.132-19.008 0-26.532l.528-.396c3.96 2.112 8.052 3.432 13.992 3.432 6.072 0 11.748-1.716 14.784-3.696 5.808-3.828 8.184-7.656 10.56-12.804 2.244-5.016 2.904-9.504 2.904-15.972.132-10.824-7.524-24.816-21.648-24.816-5.544 0-7.788.528-20.724 7.128zm35.376 20.592c0 9.504-2.772 15.708-7.26 20.064-3.564 3.432-8.58 4.884-12.804 4.884-7.656 0-14.916-4.224-14.916-7.26 0-9.9.132-21.78.132-32.472 6.072-4.488 13.068-6.864 18.612-6.864 5.676 0 10.692 3.036 13.2 7.788s3.036 9.504 3.036 13.86z" />
                <path d="m653.178 90.279c-1.716.264-3.696.528-5.412.924.66 4.884 1.32 9.636 1.848 14.52 2.508 1.188 8.184 2.64 15.444 2.64 5.94 0 11.616-1.056 14.916-3.168 5.148-3.432 7.524-7.92 7.524-13.2 0-10.032-7.524-12.804-17.028-15.576-10.692-3.3-13.332-4.224-13.332-10.296 0-3.696 1.584-6.072 3.564-7.656 2.112-1.584 5.148-2.772 9.108-2.772 3.564 0 6.732.792 8.448 2.112.528.264.792.792 1.056 1.452l1.716 8.712c1.716-.132 3.696-.528 5.28-.66-.396-4.62-.924-9.24-1.32-13.728-1.056-.66-5.148-2.508-12.144-2.508-5.808 0-10.824 1.056-14.784 3.3-3.828 2.376-7.392 6.6-7.392 12.54 0 6.996 3.696 10.824 13.992 13.992 11.616 3.696 16.236 4.488 16.236 12.144 0 4.092-1.848 6.6-3.96 8.184-3.168 2.112-6.996 2.508-11.352 2.508-3.96 0-7.656-1.452-9.504-2.904-.792-.528-.924-.924-1.188-1.716z" />
              </g>
            </g>
          </svg>
          <small
            className={`relative block md:-mt-4 mt-8 ml-1 opacity-25 text-xs uppercase tracking-widest md:ml-4 md:opacity-100`}
            style={{ top: "-2.1rem" }}
          >
            Executive Producer/
            <br className="hidden md:inline" />
            Senior Producer
          </small>
        </Link>
      </h1>
      <div className={`w-1/3 px-8`}>
        <ul className={`text-sm uppercase text-white font-sans`}>
          <li>
            <Link
              to="/"
              className="px-1 leading-none relative -mx-1 font-sans"
              style={{ paddingTop: "1px", paddingBottom: "2px" }}
              activeClassName="bg-black text-gray-400"
              partiallyActive={(() => {
                if (typeof window !== "undefined") {
                  const pathName = window.location.pathname
                  return pathName === "/" || pathName.includes("/work/")
                }
              })()}
            >
              Work
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="px-1 leading-none relative -mx-1 font-sans"
              style={{ paddingTop: "1px", paddingBottom: "2px" }}
              activeClassName="bg-black text-gray-400"
              partiallyActive={true}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="px-1 leading-none relative -mx-1 font-sans"
              style={{ paddingTop: "1px", paddingBottom: "2px" }}
              activeClassName="bg-black text-gray-400"
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Header
